import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import {} from 'env-create-react-app';

import CPADIMAGE from '../images/CPAD_onwhite_rounded.svg';

function toggleOverview() {
  const element = document.getElementById('flex');
  if (element.classList.contains('overview-hidden')) {
    document.getElementById('overview-content').style.marginLeft = '1rem';
  } else {
    const contentWidth = document.getElementById('overview-content').offsetWidth - 56;
    document.getElementById('overview-content').style.marginLeft = `-${contentWidth}px`;
  }
  element.classList.toggle('overview-hidden');
}

const Overview = () => (
  <div className="overview">
    <Button
      type="button"
      aria-label="Toggle Overview"
      className="overview_toggle toggle left"
      onClick={toggleOverview}
    >
      <i className="arrow circle left icon" />
    </Button>
    <Button
      type="button"
      aria-label="Toggle Overview"
      className="overview_toggle toggle right"
      onClick={toggleOverview}
    >
      <i className="arrow circle right icon" />
    </Button>
    <div className="content" id="overview-content">
      <Header as="h2">Overview</Header>
      <p>
        The Critical Path for Alzheimer’s Disease (CPAD) is a nonprofit, pre-competitive consortium
        of the Critical Path Institute (C-Path). The Alzheimer’s Disease-Data and Analytics Platform
        provides a centralized and standardized infrastructure for integration and analysis of
        pre-competitive patient-level data from high quality Alzheimer’s disease (AD) clinical
        trials with the goal of accelerating drug development for AD.
        {' '}
      </p>
      <p>
        You can find more information about CPAD
        {' '}
        <a href="https://c-path.org/programs/cpad/" target="_blank" rel="noreferrer">here</a>
        .
      </p>
      <img src={CPADIMAGE} alt="CPAD Logo" id="overviewLogo" />
      <p>
        Recent estimates indicate that the number of people living with dementia is expected to rise
        to 139 million cases worldwide in 2050. AD accounts for an estimated 60-80% of dementia
        cases. Despite this looming public health crisis, therapies available in the U.S. for the
        last two decades only treat disease symptoms. There is a pressing need for effective
        therapeutic interventions for AD and a growing interest in intervening earlier in the
        disease process. This requires a robust understanding of progression across all disease
        stages. CPAD focuses on pre-competitive sharing of high-quality industry clinical trial and
        observational study datasets to gain better understanding of disease progression across the
        entire continuum — the earliest stages to severe AD. This allows for optimized trial design
        and execution, reduction of cost and time, and reduced patient burden.
        {' '}
      </p>
      <Header as="h2">AD-DAP Objective</Header>
      <p>
        The AD-DAP platform provides access to patient-level data from legacy and contemporary
        clinical trials based on contributor-defined access level permissions, including key imaging
        and fluid biomarkers. The data are converted to a common regulatory-grade data standard,
        aggregated into a single dataset and analyzable across all studies.
      </p>
      <Header as="h2">How AD-DAP Works</Header>
      <p>
        AD-DAP houses integrated patient-level data, including placebo arm data from clinical trials
        for drugs currently on the market. The CPAD database is made possible by contributions from
        the following organizations: Abbott Laboratories, AstraZeneca, The Alzheimer’s Disease
        Cooperative Study, Eisai Inc., Forest Laboratories, GlaxoSmithKline, Johnson & Johnson,
        Lundbeck, Novartis, Pfizer Inc., Sanofi SA, Servier, and vTv Therapeutics. C-Path thanks
        these organizations for their generous contributions to this vital effort.
      </p>
      <p>
        The AD-DAP is available to CPAD members, and to external qualified researchers who are
        approved for access. CPAD is obligated to protect the identity of its contributors. All
        individual datasets are only accessible as one aggregated dataset. We are unable to provide
        protocols, publications, or clinicaltrials.gov record locator numbers. Item level data of
        clinical scales is present allowing investigators to analyze sub-items.
      </p>

      <p>
        If you have any questions about the structure or content of the data, please contact support
        at
        {' '}
        <a href="mailto:bstafford@c-path.org">bstafford@c-path.org</a>
        .
      </p>
    </div>
  </div>
);

export default Overview;
