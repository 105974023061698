import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/semantic.css';
import './css/portal.css';
import './css/CPATH_changes.css';
import App from './App';

// import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
