/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Banner from './components/Banner';

const bannerContent = [
  {
    bannerID: 'banner20220216a',
    displayAcknowledgeBannerButton: true,
    notificationStartTime: '',
    notificationEndTime: '2022-02-22T13:00:00+00:00',
    bannerContents: () => (
      <>
        <strong>PLEASE NOTE:</strong>
        Platform scheduled maintenance on Tuesday, February 22 from
        11:00 to 13:00 UTC (6:00-8:00 ET). Temporary service disruption is possible.
      </>
    ),
  },
];

const bannerContentsArray = bannerContent.map((x) => <Banner {...x} />);

export default bannerContentsArray;
