import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Banner = ({
  bannerID,
  displayAcknowledgeBannerButton,
  notificationStartTime,
  notificationEndTime,
  bannerContents,
  bannerShow,
}) => {
  const [bannerState, setbannerState] = useState({
    bannerShow,
  });

  if (bannerState.bannerShow) {
    const currentTime = new Date();
    const startTime = new Date(notificationStartTime);
    const endTime = new Date(notificationEndTime);

    // Use these to check whether dates are correct for banner
    // console.log(startTime);
    // console.log(endTime);

    if ((!startTime.getTime() || currentTime >= startTime)
      && (!endTime.getTime() || currentTime <= endTime)) {
      const alreadyAcknowledged = window.localStorage.getItem(bannerID);
      if (alreadyAcknowledged) {
        setbannerState({ bannerShow: false });
      }
    } else {
      setbannerState({ bannerShow: false });
    }
  }

  const acknowledgeBanner = () => {
    window.localStorage.setItem(bannerID, 'acknowledged');
    setbannerState({ bannerShow: false });
  };

  return (
    <div id={bannerID} className={((bannerState.bannerShow) ? 'banner' : 'hidden')}>
      <p style={{ paddingRight: '11rem' }}>
        {bannerContents()}
        <button
          type="button"
          aria-label="Acknowledge Banner"
          className={((displayAcknowledgeBannerButton) ? 'ui purple button' : 'hidden')}
          onClick={acknowledgeBanner}
        >
          Acknowledge
        </button>
      </p>
    </div>
  );
};

Banner.defaultProps = {
  bannerShow: true,
};

Banner.propTypes = {
  bannerID: PropTypes.string.isRequired,
  displayAcknowledgeBannerButton: PropTypes.bool.isRequired,
  notificationStartTime: PropTypes.string.isRequired,
  notificationEndTime: PropTypes.string.isRequired,
  bannerContents: PropTypes.func.isRequired,
  bannerShow: PropTypes.bool,
};

export default Banner;
